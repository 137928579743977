import dummyData from "../../helpers/dummyPostData";
import DataTable from "../table/DataTable";
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { EDIT_BLOG_ROUTE } from "../../routes/routeConstants";
import { EditBlogContext } from "../../context/editBlogContext";
import apiService, { apiGET } from "../../services/apiService";

const ViewPost = () => {
  const { editBlogContext, setEditBlogContext } = useContext(EditBlogContext);
  const [ allPosts, setAllPosts ] = useState([]);
  const navigate = useNavigate();

  const headers = [
    'Autor',
    'Tip',
    'Title',
    'Kratak Opis',
    'Pod naslov',
    'Slika',
    'Opis',
    'Video',
    'Kratak Opis',
    'Galerija',
    'Izmeni/Obrisi'
  ];

  const yourData = dummyData;

  // Number of items to display per page
  const itemsPerPage = 10;
    
  const editBlog = (post) => {
    setEditBlogContext(post);
    navigate(EDIT_BLOG_ROUTE);
  };
  
  const getBlogs = async() => {
      const [success, error] = await apiService.get('posts');

      if(error) {
        toast.error("Greska pri ucitavanju blogova!");
        return; 
      }

      setAllPosts(success.data)
  }

  useEffect(() => {
    getBlogs()
  }, []);

  return (
    <div className="px-5 py-5">
      <h1>Svi blogovi</h1>
      <DataTable data={allPosts} headers={headers} itemsPerPage={itemsPerPage} edit={editBlog}/>
    </div>
  );
};

export default ViewPost;

