import React from 'react';
import { Navbar as BootstrapNavbar, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { CREATE_BLOG_ROUTE, CREATE_GAME_WEEK_ROUTE, CREATE_MATCH_ROUTE, CREATE_QUESTION_ROUTE, CREATE_QUIZ_ROUTE, VIEW_BLOGS_ROUTE, VIEW_GAME_WEEKS_ROUTE, VIEW_MATCHES_ROUTE, VIEW_QUESTIONS_ROUTE, VIEW_QUIZZES_ROUTE } from '../../routes/routeConstants';

const Navigation = () => {
  return (
    <BootstrapNavbar bg="dark" variant="dark" expand="lg" className='px-5'>
      <LinkContainer to={VIEW_BLOGS_ROUTE}>
        <BootstrapNavbar.Brand>Heineken CMS</BootstrapNavbar.Brand>
      </LinkContainer>
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">

          <NavDropdown title="Blogovi" id="basic-nav-dropdown">
            <LinkContainer to={VIEW_BLOGS_ROUTE}>
              <NavDropdown.Item>Pregled blogova</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to={CREATE_BLOG_ROUTE}>
              <NavDropdown.Item>Kreiranje bloga</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>

          <NavDropdown title="Kvizovi" id="basic-nav-dropdown">
            <LinkContainer to={VIEW_QUIZZES_ROUTE}>
              <NavDropdown.Item>Pregled kvizova</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to={CREATE_QUIZ_ROUTE}>
              <NavDropdown.Item>Kreiranje kviza</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to={CREATE_QUESTION_ROUTE}>
              <NavDropdown.Item>Kreiranje pitanja</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to={VIEW_QUESTIONS_ROUTE}>
              <NavDropdown.Item>Pregled pitanja</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>

          {/* <NavDropdown title="Prediktor" id="basic-nav-dropdown">
            <LinkContainer to={CREATE_MATCH_ROUTE}>
              <NavDropdown.Item>Kreiranje meca</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to={VIEW_MATCHES_ROUTE}>
              <NavDropdown.Item>Pregled meceva</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to={CREATE_GAME_WEEK_ROUTE}>
              <NavDropdown.Item>Kreiranje kola</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to={VIEW_GAME_WEEKS_ROUTE}>
              <NavDropdown.Item>Pregled kola</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown> */}
            <NavDropdown title="App Data" id="basic-nav-dropdown">
            <LinkContainer to="app/ranks">
              <NavDropdown.Item>Rang List</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="app/data">
              <NavDropdown.Item>Statistika</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>

        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default Navigation;