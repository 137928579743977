import React, { useState } from "react";

const QuestionCard = ({
  answers,
  errors,
  questionText,
  questionImageSrc,
  handleQuestionTextChange,
  handleImageChange,
  handleAnswerTextChange,
  handleCorrectAnswerChange,
}) => {
  return (
    <div className="card my-3">
      <div className="card-body">
        <h5 className="card-title">Kreiranje pitanja</h5>

        <label htmlFor={`imageInput`} className="form-label">
          Izaberi sliku
        </label>
        <input
          type="file"
          accept=".jpg, .jpeg, .png"
          className={`form-control ${errors.questionImage ? 'border-danger' : ''}`}
          id={`imageInput`}
          onChange={(e) => {
            handleImageChange(e.target.files[0]);
          }}
        />
        {questionImageSrc && (
          <img
            crossOrigin='anonymous'
            style={{ width: "200px", height: "200px" }}
            src={questionImageSrc}
            className="mt-2"
          />
          )}
        {errors.questionImage && (
          <div className="text-danger">{errors.questionImage}</div>
        )}
          <br></br>

        <label htmlFor={`questionInput`} className="form-label">
          Pitanje:
        </label>
        <input
          type="text"
          className={`form-control ${errors.questionText ? 'is-invalid' : ''}`}
          id={`questionInput`}
          placeholder="Unesi tekst pitanja"
          value={questionText}
          onChange={(e) => handleQuestionTextChange(e.target.value)}
        />

        {errors.questionImage && (
          <div className="text-danger">{errors.questionImage}</div>
        )}
        <label className="form-label">Odgovori:</label>

        {answers.map((answer, answerIndex) => (
          <div key={answerIndex} className={`mb-3 ${errors.answers[answerIndex] ? 'is-invalid' : ''}`}>
            <div className="row">
            <div className="col-md-2 mt-1">
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    name={`correctAnswer`}
                    value={answers[answerIndex].isCorrect}
                    checked={answers[answerIndex].isCorrect}
                    onChange={() => handleCorrectAnswerChange(answerIndex)}
                  />
                  <label className="form-check-label">
                    {answers[answerIndex].isCorrect ? "Tacan odgovor" : ""}
                  </label>
                </div>
              </div>
              <div className={"col-md-10"}>
                <input
                  type="text"
                  placeholder={`Unesi odgovor ${answerIndex + 1}`}
                  value={answer.text}
                  className={`form-control ${errors.answers[answerIndex] ? 'is-invalid' : ''}`}
                  onChange={(e) =>
                    handleAnswerTextChange(answerIndex, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionCard;
