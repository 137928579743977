//blog
export const EDIT_BLOG_ROUTE = '/blog/edit';
export const VIEW_BLOGS_ROUTE = '/blog/view';
export const CREATE_BLOG_ROUTE = '/blog/create';
//quiz
export const EDIT_QUIZ_ROUTE = '/quiz/edit';
export const VIEW_QUIZZES_ROUTE = '/quiz/view';
export const CREATE_QUIZ_ROUTE = '/quiz/create';
//login
export const LOGIN = '/login';
//question
export const CREATE_QUESTION_ROUTE = '/question/create';
export const VIEW_QUESTIONS_ROUTE = '/question/view';
export const EDIT_QUESTION_ROUTE = '/question/edit';
//match
export const CREATE_MATCH_ROUTE = '/match/create';
export const VIEW_MATCHES_ROUTE = '/match/view';
export const EDIT_MATCH_ROUTE = '/match/edit';
export const EDIT_MATCH_RESULTS_ROUTE = '/match/add-result';
//game week
export const CREATE_GAME_WEEK_ROUTE = '/game-week/create';
export const VIEW_GAME_WEEKS_ROUTE = '/game-week/view';
export const EDIT_GAME_WEEK_ROUTE = '/game-week/edit';