import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;
const apiService = {
    get: async (path) => {
        try {
            var { success } = await axios.get(baseUrl + path);
            return [success, null]
        } catch (error) {
            if(error?.response?.status === 401) {
                localStorage.removeItem("token")
                window.location.href = '/login';
            }
            return [null, error]
        }
    },

    put: async (path, data) => {
        try {
            var { success } = await axios.put(baseUrl + path, data);
            return [success, null]
        } catch (error) {
            if(error?.response?.status === 401) {
                localStorage.removeItem("token")
                window.location.href = '/login';
            }
            return [null, error]
        }
    },

    post: async (path, data) => {
        try {
            var { success } = await axios.post(baseUrl + path, data);
            return [success, null]
        } catch (error) {
            if(error?.response?.status === 401) {
                localStorage.removeItem("token")
                window.location.href = '/login';
            }
            return [null, error]
        }
    },

    delete: async (path) => {
        try {
            var { success } = await axios.delete(baseUrl + path);
            return [success, null]
        } catch (error) {
            if(error?.response?.status === 401) {
                localStorage.removeItem("token")
                window.location.href = '/login';
            }
            return [null, error]
        }
    },

    patch: async (path, data) => {
        try {
            var { success } = await axios.patch(baseUrl + path, data);
            return [success, null]
        } catch (error) {
            if(error?.response?.status === 401) {
                localStorage.removeItem("token")
                window.location.href = '/login';
            }
            return [null, error]
        }
    }
  };
  
export default apiService;