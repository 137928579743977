import moment from "moment/moment";

export const createDateTimeRangeForHalfOfMonth = (yearMonth, subQuizType) => {
  const chosenMonth = parseInt(yearMonth.split("-")[1], 10);
  
  if (chosenMonth < 1 || chosenMonth > 12) {
    throw new Error("Invalid month. Month should be between 1 and 12.");
  }

  let startDay, endDay;
  if (subQuizType == "entire") {
    startDay = 15;
  } else {
    startDay = 1;
  }
  
  endDay = 14;

  const startDate = moment({
    year: moment().year(),
    month: chosenMonth - 1,
    day: startDay,
    hours: 0,
    minutes: 0,
    seconds: 1,
  });
  const endDate = moment({
    year: moment().year(),
    month: subQuizType !== "entire" ? chosenMonth - 1 : chosenMonth,
    day: endDay,
    hours: 23,
    minutes: 59,
    seconds: 59,
  });

  return {
    startDate: startDate, 
    endDate: endDate, 
  };
};

