import React from 'react';
import MatchForm from '../components/match/matchForm';

function CreateMatch() {
  return (
    <div>
      <MatchForm/>
    </div>
  );
}

export default CreateMatch;