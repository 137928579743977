import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiService from '../services/apiService';
import { EDIT_QUESTION_ROUTE } from '../routes/routeConstants';
import ViewQuestionTable from '../components/quiz/components/ViewQuestionTable';

const  ViewQuestions = () => {
  const [ allQuestions, setAllQuestions ] = useState([]);
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const headers = [
    'Tekst pitanja',
    'Odgovor 1',
    'Odgovor 2',
    'Odgovor 3',
    'Odgovor 4',
    'Poeni',
    'Slika',
    'Pregledaj/Obrisi'
  ];
    
  const edit = (question) => {
    navigate(EDIT_QUESTION_ROUTE + "/" + question._id);
  };

  const getQuestions = async () => {
    const [success, error] = await apiService.get('questions');

    if (error) {
      toast.error("Greska pri ucitavanju pitanja!");
      return;
    }

      setAllQuestions(success.data)
  }

  useEffect(() => {
    getQuestions()
  }, []);

  return (
    <div className="px-5 py-5">
      <h1>Sva pitanja</h1>
      <ViewQuestionTable data={allQuestions} headers={headers} itemsPerPage={itemsPerPage} edit={edit}/>
    </div>
  );
}

export default ViewQuestions;