import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import moment from "moment";

const MatchSummary = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [matchDate, setMatchDate] = useState("");
  const [totalGoals, setGoals] = useState(0);
  const [totalFouls, setFouls] = useState(0);
  const [moreCorners, setCorners] = useState("");
  const [moreShoots, setShotsOnGoal] = useState("");
  const [bothTeamsScored, setBothTeamsScored] = useState(true);

  const handleCornersChange = (e) => {
    setCorners(e.target.value);
  };

  const handleShotsOnGoalChange = (e) => {
    setShotsOnGoal(e.target.value);
  };

  const getMatch = async (id) => {
    const [success, error] = await apiService.get(`match/${id}`);

    if (error) {
      toast.error("Greska pri ucitavanju meceva!");
      return;
    }

    setTeam1(success.data.homeTeam);
    setTeam2(success.data.awayTeam);
    setMatchDate(success.data.matchDate);
    setGoals(success.data.totalGoals);
    setFouls(success.data.totalFouls);
    setCorners(success.data.moreCorners);
    setShotsOnGoal(success.data.moreShoots);
    setBothTeamsScored(success.data.bothTeamScores);
  };

  const submitMatchSummary = async () => {
    const data = {
      totalGoals: Number(totalGoals),
      totalFouls: Number(totalFouls),
      moreCorners: Number(moreCorners),
      moreShoots: Number(moreShoots),
      bothTeamScores: bothTeamsScored
    };

    const [success, error] = await apiService.patch(`match/${id}`, data);

    if (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error.response.data.error.message);
        return;
      }
      toast.error("Greska pri unosu rezultata!");
      return;
    }

    toast.success("Rezultati su uneti!");
    navigate("/match/view");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    submitMatchSummary();
  };

  const handleBothTeamsScored = (e) => {
    setBothTeamsScored(e.target.value === 'true')
  }

  useEffect(() => {
    if (id) {
      getMatch(id);
    }
  }, []);

  return (
    <form onSubmit={(e) => handleFormSubmit(e)}>
      <div className="container py-4">
        <h2 className="mb-4">Rezultati meca</h2>
        <div className="mb-3">
          <label className="form-label" htmlFor="team1">
            Domaci tim:
          </label>
          <input
            type="text"
            id="team1"
            name="team1"
            value={team1.name}
            className="form-control"
            disabled={true}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="team2">
            Gostujuci tim:
          </label>
          <input
            type="text"
            id="team2"
            name="team2"
            value={team2.name}
            disabled={true}
            className="form-control"
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="startDate">
            Datum pocetka:
          </label>
          <input
            type="text"
            id="startDate"
            name="startDate"
            value={moment(matchDate).format("DD-MM-YYYY HH:mm")}
            className="form-control"
            disabled={true}
            required
          />
        </div>

        <div className="form-group">
          <label>Oba tima daju gol: </label>
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="both_scored"
                value="true"
                checked={bothTeamsScored === true}
                onChange={handleBothTeamsScored}
              />
              <label className="form-check-label" htmlFor="team_a_yes">
                DA
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="both_scored"
                value="false"
                checked={bothTeamsScored === false}
                onChange={handleBothTeamsScored}
              />
              <label className="form-check-label" htmlFor="team_a_no">
                NE
              </label>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="goals">
            Ukupno golova:
          </label>
          <input
            type="number"
            id="goals"
            name="goals"
            value={totalGoals}
            className="form-control"
            onChange={(e) => setGoals(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="fouls">
            Ukupno faulova:
          </label>
          <select
            id="fouls"
            name="fouls"
            value={totalFouls}
            onChange={(e) => setFouls(e.target.value)}
            className="form-select"
            required
          >
            <option value="">Izaberi</option>
            <option value="1">0-6</option>
            <option value="2">7-12</option>
            <option value="3">13-19</option>
            <option value="4">20+</option>
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="corners">
            Vise kornera:
          </label>
          <select
            id="corners"
            name="corners"
            value={moreCorners}
            onChange={handleCornersChange}
            className="form-select"
            required
          >
            <option value="">Izaberi</option>
            <option value="1">Domaci tim</option>
            <option value="2">Gostujuci tim</option>
            <option value="3">Isto</option>
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="shotsOnGoal">
            Vise suteva u okvir gola:
          </label>
          <select
            id="shotsOnGoal"
            name="shotsOnGoal"
            value={moreShoots}
            onChange={handleShotsOnGoalChange}
            className="form-select"
            required
          >
            <option value="">Izaberi</option>
            <option value="1">Domaci tim</option>
            <option value="2">Gostujuci tim</option>
            <option value="3">Isto</option>
          </select>
        </div>

        <button className="mt-3 btn btn-primary" type="submit">
          SAČUVAJ
        </button>
      </div>
    </form>
  );
};

export default MatchSummary;
