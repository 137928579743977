export const fillQuestions = () => {
  return Array(4)
    .fill({
      text: "",
      isCorrect: false,
    })
    .map((answer, index) => {
      return { ...answer, isCorrect: index === 0 };
    });
};
