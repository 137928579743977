import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Navigation from "../components/navigation/Navbar";

const isAuthenticated = () => {
  var token = localStorage.getItem("token");
  if (token == null) {
    return false;
  } else {
    return true;
  }
};

export const ProtectedRoute = () => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex-order">
      <Navigation />
      <Outlet className="example" />
    </div>
  );
};
