import React, { useEffect, useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import './table.css';
import axios from 'axios';


const DataTable = ({ data = [], itemsPerPage, edit, headers }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;


  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deletePost = (post) => {
    axios.delete(process.env.REACT_APP_API_URL + 'posts/' + post._id)
    .then((res) => {
      setCurrentItems(prevActions => (
        // Filter out the item with the matching index
        prevActions.filter((value, i) => value._id !== post._id)
      ));
      window.alert('Uspesno brisanje');
    })
    .catch((error) => {
      window.alert('Neuspelo brisanje');
    });
  };

  useEffect(() => {
    setCurrentItems(data?.slice(indexOfFirstItem, indexOfLastItem));
  },[data, currentPage])

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((post, index) => (
            <tr key={index}>
              <td className='text-overflow'>{post?.author?.firstName + " " + post?.author?.lastName}</td>
              <td className='text-overflow'>{post?.postType}</td>
              <td className='text-overflow'>{post?.title}</td>
              <td className='text-overflow'>{post?.shortDescription}</td>
              <td className='text-overflow'>{post?.subTitle}</td>
              <td className='text-overflow'>{post?.contentImage}</td>
              <td className='text-overflow'>{post?.description}</td>
              <td className='text-overflow'>{post?.contentVideo}</td>
              <td className='text-overflow'>{post?.description2}</td>
              <td className='text-overflow'>{post?.contentGallery}</td>
              <td ><button  type="button" class="btn btn-primary" onClick={() => edit(post)}> Izmeni</button><button  type="button" class="btn btn-danger" onClick={() => deletePost(post)}> Obrisi</button></td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination>
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages).keys()]?.map((number) => (
          <Pagination.Item
            key={number + 1}
            active={number + 1 === currentPage}
            onClick={() => paginate(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </div>
  );
};

export default DataTable;