import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MatchHeaders from "../table/headers/matchHeaders";
import MatchTableBody from "../table/bodies/matchTableBody";
import apiService from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import { editQuestion } from "../../signals";
import { EDIT_MATCH_RESULTS_ROUTE, EDIT_MATCH_ROUTE } from "../../routes/routeConstants";
import ViewTable from "../table/ViewTable";
import "../table/table.css";

const ViewMatches = () => {
  const navigate = useNavigate();
  const [matches, setMatches] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  const deleteMatch = async (match) => {
    const [success, error] = await apiService.delete("match/" + match._id);

    if (error) {
      toast.error("Neuspelo brisanje");
      return;
    }

    setCurrentItems((prevActions) =>
      prevActions.filter((value, i) => value._id !== match._id)
    );
    toast.success("Uspesno brisanje");
  };

  const edit = (match) => {
    editQuestion.value = match;
    navigate(EDIT_MATCH_ROUTE+ '/' + match._id);
  };

  const getMatches = async () => {
    const [success, error] = await apiService.get("match");

    if (error) {
      toast.error("Greska pri ucitavanju meceva!");
      return;
    }

    setMatches(success.data);
  };

  const addResult = (match) => {
    // navigate to match summary
    navigate(EDIT_MATCH_RESULTS_ROUTE + '/' + match._id);
  };

  useEffect(() => {
    getMatches();
  }, []);

  return (
    <div className="px-5 py-5">
      <h1>Svi mecevi</h1>
      <ViewTable
        setCurrentItems={setCurrentItems}
        itemsPerPage={10}
        data={matches}
      >
        <MatchHeaders />
        <MatchTableBody
          data={currentItems}
          edit={edit}
          deleteMatch={deleteMatch}
          addResult={addResult}
        />
      </ViewTable>
    </div>
  );
};

export default ViewMatches;
