import "../App.css";
import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute.js";
import CreateBlog from "../components/blog/CreateBlog.js";
import ViewBlogs from "../components/blog/ViewBlogs.js";
import LoginForm from "../components/login/Login.js";
import CreateQuiz from "../pages/CreateQuiz.js";
import CreateQuestion from "../pages/CreateQuestion.js";
import ViewQuizzes from "../pages/ViewQuizzes.js";
import ViewQuestions from "../pages/ViewQuestions.js";
import { PublicRoute } from "./publicRoute.js";
import CreateMatch from "../pages/CreateMatch.js";
import ViewMatches from "../components/match/ViewMatches.js";
import ViewGameWeek from "../components/gameWeek/viewGameWeeks.js";
import CreateGameWeekForm from "../components/gameWeek/createGameWeek.js";
import MatchSummary from "../components/match/MatchSummary.js";
import ViewRankList from "../pages/ViewRankList.js";
import ViewAppData from "../pages/ViewAppData.js";

function AppRoutes() {
  return (
    <Routes>
    <Route path="/*" element={<PublicRoute />}>
      <Route path="login" element={<LoginForm />} />
    </Route>

    <Route path="/*" element={<ProtectedRoute />}>
      <Route path="blog">
        <Route path="view" element={<ViewBlogs />} />
        <Route path="create" element={<CreateBlog key={"create"}/>} />
        <Route path="edit" element={<CreateBlog key={"edit"}/>} />
      </Route>

      <Route path="quiz">
        <Route path="view" element={<ViewQuizzes />} />
        <Route path="create" element={<CreateQuiz key={"create"}/>} />
        <Route path="edit/:id" element={<CreateQuiz key={"edit"}/>} />
      </Route>

      <Route path="question">
        <Route path="view" element={<ViewQuestions />} />
        <Route path="create" element={<CreateQuestion key={"create"}/>} />
        <Route path="edit/:id" element={<CreateQuestion key={"edit"}/>} />
      </Route>

      <Route path="match">
        <Route path="view" element={<ViewMatches />} />
        <Route path="create" element={<CreateMatch key={"create"}/>} />
        <Route path="edit/:id" element={<CreateMatch key={"edit"}/>} />
        <Route path="add-result/:id" element={<MatchSummary />} />
      </Route>

      {/* <Route path="game-week">
        <Route path="view" element={<ViewGameWeek />} />
        <Route path="create" element={<CreateGameWeekForm key={"create"}/>} />
        <Route path="edit" element={<CreateQuiz key={"edit"}/>} />
      </Route> */}

      <Route path="app">
        <Route path="ranks" element={<ViewRankList />} />
        <Route path="data" element={<ViewAppData />} />
      </Route>

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Route>
  </Routes>
  )
}

export default AppRoutes;
