import React, { useEffect, useState } from "react";
import QuestionCard from "./components/QuestionCard";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { fillQuestions } from "../../helpers/fillQuestions";
import { editQuestion } from "../../signals";
import SpinnerOverlay from "../../helpers/SpinnerOverlay";

const QuestionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [questionText, setQuestionText] = useState("");
  const [questionImage, setQuestionImage] = useState(null);
  const [questionImageSrc, setQuestionImageSrc] = useState("");
  const [answers, setAnswers] = useState(fillQuestions());
  const [errors, setErrors] = useState({
    questionText: false,
    answers: Array(4).fill(false),
    questionImage: false,
  });

  const handleQuestionTextChange = (value) => {
    setQuestionText(value);
  };

  const handleImageChange = (file) => {
    setQuestionImage(file);
    setQuestionImageSrc(URL.createObjectURL(file));
  };

  const handleAnswerTextChange = (answerIndex, value) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer, index) => {
        if (index === answerIndex) {
          return { ...answer, text: value };
        }
        return answer;
      })
    );
  };

  const handleCorrectAnswerChange = (answerIndex) => {
    const updatedAnswers = answers.map((answer, idx) => ({
      ...answer,
      isCorrect: idx === answerIndex,
    }));
    setAnswers(updatedAnswers);
  };

  const isFormValid = () => {
    var isValid = false;
    var newErrors = {
      ...errors,
      questionText: questionText.trim() == "",
      answers: answers.map((answer) => answer.text.trim() == ""),
      questionImage: questionImage == "" || questionImage == null,
    };

    isValid =
      !newErrors.questionText &&
      !newErrors.questionImage &&
      !newErrors.answers.some((answer) => answer);
    setErrors(newErrors);

    return isValid;
  };

  const createQuestion = async (formData) => {
    const [success, error] = await apiService.post("questions", formData);

    setLoading(false);
    if (error) {
      toast.error("Pitanje nije kreirano!");
      return;
    }

    toast.success("Pitanje uspesno kreirano!");
    navigate("/question/view");
  };

  const deleteQuestion = async () => {
    const [success, error] = await apiService.delete(
      "questions/" + editQuestion.value._id
    );

    setLoading(false);
    if (error) {
      toast.error("Pitanje nije obrisano!");
      return;
    }

    navigate("/question/view");
    toast.success("Pitanje uspesno obrisano!");
    editQuestion.value = null;
  }

  const getQuestion = async () => {
    const [success, error] = await apiService.get(`questions/${id}`);

    if (error) {
      toast.error("Greska pri dohvatanju pitanja!");
      return;
    }
    if (success.data) {
      const { questionText, answerOptions, questionImage } = success.data;
      setAnswers(answerOptions);
      setQuestionText(questionText);
      setQuestionImageSrc(process.env.REACT_APP_CONTENT_URL + questionImage);
    }
  };

  useEffect(() => {
    if(id) {
      getQuestion();
    }
  }, [])

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (id) {
      deleteQuestion();
      return;
    }

    if (!isFormValid()) {
      return;
    }

    const formData = new FormData();
    formData.append("questionText", questionText);
    formData.append("questionImage", questionImage);
    formData.append("answerOptions", JSON.stringify(answers));
    formData.append("points", 10);

    setLoading(true);
    createQuestion(formData);
  };

  return (
    <div>
      <form noValidate onSubmit={(e) => handleFormSubmit(e)}>
        <QuestionCard
          questionImageSrc={questionImageSrc}
          questionText={questionText}
          answers={answers}
          errors={errors}
          handleQuestionTextChange={handleQuestionTextChange}
          handleImageChange={handleImageChange}
          handleAnswerTextChange={handleAnswerTextChange}
          handleCorrectAnswerChange={handleCorrectAnswerChange}
        />
        <div className="text-center">
        {!id && <button type="submit" disabled={loading} className="btn mb-2 btn-primary">
           Kreiraj
          </button>}

          {id && <button type="submit" disabled={loading} className="btn mb-2 btn-danger">
             Obrisi
          </button>}
        </div>
      </form>
      <SpinnerOverlay isLoading={loading} />
    </div>
  );
};

export default QuestionForm;
