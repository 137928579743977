const headers = [
    'Game Week',
    'Naziv tima 1',
    'Naziv tima 2',
    'Vreme pocetka',
    'Vreme kreiranja',
    'Vreme izmene',
    'Pregled/Unesi rezultat/Obrisi'
  ];

const MatchHeaders = () => {
    return (
        <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
    )
}

export default MatchHeaders;