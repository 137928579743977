import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiService from "../services/apiService";

const ViewAppData = () => {
  const [appData, setAppData] = useState({
    usersData: {
      ritualNotNullCount: 0,
      playedPredictorCount: 0,
      isActiveTrueCount: 0,
      isActiveFalseCount: 0,
    },
    quizData: {
      uncompleted: 0,
      completed: 0,
    },
    ritualData: {
      maxRitual: {
        description:
          "Kada gledam utakmicu na stadionu, uvek kupujem semenke kod istog prodavca.",
        ritualCount: 9,
      },
      minRitual: {
        description: "Ne ustajem sa svog srećnog mesta dok moj tim pobeđuje.",
        ritualCount: 1,
      },
    },
  });

  const getAppData = async () => {
    const [success, error] = await apiService.get("/ranks/data");

    if (error) {
      toast.error("Greska pri ucitavanju podataka applikacije!");
      return;
    }

    setAppData(success.data);
  };

  useEffect(() => {
    getAppData();
  }, []);

  return (
    <div className="px-5 py-5">
      <div className="table-responsive">
        <h2>Podaci o korisnicima</h2>
        <table className="table table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              <th className="text-center">Broj Igraca Rituala</th>
              <th className="text-center">Broj Igraca UCL Rezultata</th>
              <th className="text-center">Broj Potvrdjenih Naloga</th>
              <th className="text-center">Broj Nepotvrdjenih Naloga</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                {appData.usersData.ritualNotNullCount}
              </td>
              <td className="text-center">
                {appData.usersData.playedPredictorCount}
              </td>
              <td className="text-center">
                {appData.usersData.isActiveTrueCount}
              </td>
              <td className="text-center">
                {appData.usersData.isActiveFalseCount}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div className="table-responsive">
        <h2>Podaci o kvizovima</h2>
        <table className="table table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              <th className="text-center">Zavrsili kviz uspesno</th>
              <th className="text-center">Broj Nezavrsenih</th>
              <th className="text-center">Broj Zavrsenih</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                {appData.quizData.completedTrueCount}
              </td>
              <td className="text-center">{appData.quizData.uncompleted}</td>
              <td className="text-center">{appData.quizData.completed}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div className="table-responsive">
        <h2>Podaci o ritualima</h2>
        <table className="table table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              <th className="text-center">Opis</th>
              <th className="text-center">Broj Ponavljanja</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                {appData.ritualData.maxRitual.description}
              </td>
              <td className="text-center">
                {appData.ritualData.maxRitual.ritualCount}
              </td>
            </tr>
            <tr>
              <td className="text-center">
                {appData.ritualData.minRitual.description}
              </td>
              <td className="text-center">
                {appData.ritualData.minRitual.ritualCount}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewAppData;
