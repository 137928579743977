import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import "./MatchForm.css";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

const MatchForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [allTeams, setAllTeams] = useState(null);
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [validated, setValidated] = useState(false);
  const contentUrl = process.env.REACT_APP_IMG_URL;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if(id) {
      deleteMatch(id);
      return;
    }

    if (form.checkValidity() === true) {
      const matchData = {
        "homeTeam": homeTeam,
        "awayTeam": awayTeam,
        "matchDate": dayjs(startDate).toISOString()
      }

      createMatch(matchData)
    }

    setValidated(true);
  };

  const deleteMatch = async (id) => {
    const [success, error] = await apiService.delete(`match/${id}`);

    if (error) {
      toast.error("Greska pri brisanju meca!");
      return;
    }

    toast.success("Mec je uspešno obrisan!");
    navigate('/match/view');
  }

  const createMatch = async (data) => {
    const [success, error] = await apiService.post("match", data);

    if (error) {
      toast.error("Greska pri kreiranju meca!");
      return;
    }

    toast.success("Mec je uspešno kreiran!");
    navigate('/match/view');
  };

  const getTeams = async (data) => {
    const [success, error] = await apiService.get("teams", data);

    if (error) {
      toast.error("Greska pri dohvatanju timova!");
      return;
    }

    setAllTeams(success.data);
    if(id) {
      getMatch(id)
    }
  };

  const handleSetHomeTeam = (value) => {
    if (awayTeam === value) {
      setAwayTeam("");
    }

    setHomeTeam(value);
  }

  const handleSetAwayTeam = (value) => {
    if (homeTeam === value) {
      setHomeTeam("");
    }
    setAwayTeam(value);
  }

  const handleDateChange = (e) => {
    setStartDate(e);
  };

  const getMatch = async (id) => {
    const [success, error] = await apiService.get(`match/${id}`);

    if (error) {
      toast.error("Greska pri ucitavanju meca!");
      return;
    }

    setHomeTeam(success.data.homeTeam._id);
    setAwayTeam(success.data.awayTeam._id);
    setStartDate(dayjs(success.data.matchDate));
  };

  useEffect(() => {
    getTeams();
  }, []);

  return (
    <Container className="match-form-container">
      <form
        noValidate
        className={`match-form ${validated && "was-validated"}`}
        onSubmit={handleSubmit}
      >
        <div className="form-group">
          <label htmlFor="homeTeam">Domaci tim: </label>
          {/* <img crossOrigin="anonymous" src={contentUrl + "club-logos/antwerp.png"} /> */}
          <select
            id="homeTeam"
            required
            value={homeTeam}
            onChange={(e) => handleSetHomeTeam(e.target.value)}
            className="form-control"
            disabled={id}
            key="homeTeam"
          >
            <option value="">Selektuj Domaci Tim</option>
            {allTeams && allTeams.map((team) => (
            <option key={team._id} value={team._id}  style={{backgroundImage: `${contentUrl + team.logo}`}}>
             <img crossOrigin="anonymous" src={contentUrl + team.logo} alt={team.name} style={{width: '20px', marginRight: '10px'}} /> {team.name} 
            </option>
          ))}
          </select>
          <div className="invalid-feedback">Please select Team 1.</div>
        </div>

        <div className="form-group">
          <label htmlFor="awayTeam">Gostujuci tim:</label>
          <select
            id="awayTeam"
            required
            value={awayTeam}
            onChange={(e) => handleSetAwayTeam(e.target.value)}
            className="form-control"
            disabled={id}
          >
            <option value="">Selektuj Gostujuci Tim</option>
            {allTeams && allTeams.length > 0 && allTeams.map((team) => {
              return <option value={team._id}>{team.name} <img crossOrigin="anonymous" src={contentUrl + team.logo} /> </option>
            })}
          </select>
          <div className="invalid-feedback">Please select Team 2.</div>
        </div>

        <div className="form-group">
          <label htmlFor="startDate">Vreme početka:</label>
          <DateTimePicker
            value={startDate}
            className={`form-control ${
              validated && !startDate && "is-invalid"
            }`}
            required
            disabled={id}
            onChange={(newDate) => handleDateChange(newDate)}
            timeFormat="HH:mm"
            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
          />
          <div className="invalid-feedback">Please provide a start date.</div>
        </div>

        {!id && <button type="submit" className="btn mb-2 btn-primary">
           Kreiraj
          </button>}

          {id && <button type="submit" className="btn mb-2 btn-danger">
             Obrisi
          </button>}
      </form>
    </Container>
  );
};

export default MatchForm;
