import "./App.css";
import React, { useState } from "react";
import AppRoutes from "./routes/AppRoutes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./services/interceptor.js";
import { EditBlogContext } from "./context/editBlogContext.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import "moment/locale/sr";

function App() {
  const [editBlogContext, setEditBlogContext] = useState(null);

  return (
    <EditBlogContext.Provider value={{ editBlogContext, setEditBlogContext }}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="sr">
        <AppRoutes />
        <ToastContainer autoClose={3000} pauseOnFocusLoss={false} />
      </LocalizationProvider>
    </EditBlogContext.Provider>
  );
}

export default App;
