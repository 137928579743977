import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useMatch, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EditBlogContext } from "../../context/editBlogContext";
import SpinnerOverlay from "../../helpers/SpinnerOverlay";

const CreatePost = () => {
  const { editBlogContext } = useContext(EditBlogContext);
  const isEditRoute = useMatch("/blog/edit");
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState("");
  const [thumnailSrc, setThumbnailSrc] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [galerySrc, setGalerySrc] = useState([]);
  const [loading, setBlogLoading] = useState(false);

  // State variables to store form field values and validation errors
  const [postType, setPostType] = useState(
    editBlogContext ? editBlogContext.postType : ""
  );
  const [title, setTitle] = useState(
    editBlogContext ? editBlogContext.title : ""
  );
  const [shortDescription, setShortDescription] = useState(
    editBlogContext ? editBlogContext.shortDescription : ""
  );
  const [subTitle, setSubTitle] = useState(
    editBlogContext ? editBlogContext.subTitle : ""
  );
  const [contentImage, setContentImage] = useState(
    editBlogContext ? editBlogContext.contentImage : ""
  );
  const [contentThumbnail, setContentThumbnail] = useState(
    editBlogContext ? editBlogContext.thumbnail : ""
  );
  const [description, setDescription] = useState(
    editBlogContext ? editBlogContext.description : ""
  );
  const [contentVideo, setContentVideo] = useState(
    editBlogContext ? editBlogContext.contentVideo : ""
  );
  const [description2, setDescription2] = useState(
    editBlogContext ? editBlogContext.description2 : ""
  );
  const [contentImageGallery, setContentImageGallery] = useState(
    editBlogContext ? editBlogContext.contentGallery : ""
  );

  // State variables for validation errors
  const [errors, setErrors] = useState({
    postType: "",
    title: "",
    shortDescription: "",
    subTitle: "",
    contentImage: "",
    contentThumbnail: "",
    description: "",
    contentVideo: "",
    description2: "",
    contentImageGallery: "",
  });

  useEffect(() => {
    if (isEditRoute && editBlogContext) {
      setImgSrc(
        process.env.REACT_APP_CONTENT_URL + editBlogContext.contentImage
      );
      setThumbnailSrc(
        process.env.REACT_APP_CONTENT_URL + editBlogContext.thumbnail
      );
      setVideoSrc(
        process.env.REACT_APP_CONTENT_URL + editBlogContext.contentVideo
      );
      if (editBlogContext.contentGallery != null) {
        setGalerySrc(
          editBlogContext.contentGallery.map(
            (e) => process.env.REACT_APP_CONTENT_URL + e
          )
        );
      }
    } else {
      // reset all fields
      setPostType("");
      setSubTitle("");
      setTitle("");
      setShortDescription("");
      setDescription("");
      setContentImage("");
      setContentThumbnail("");
      setContentVideo("");
      setDescription2("");
      setContentImageGallery("");
      setErrors({});
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!postType) newErrors.postType = "Post Type is required";
    if (!title) newErrors.title = "Title is required";
    if (!shortDescription)
      newErrors.shortDescription = "Short Description is required";
    if (!subTitle) newErrors.subTitle = "Sub Title is required";
    if (!contentImage) newErrors.contentImage = "Content Image URL is required";
    if (!contentThumbnail) newErrors.contentThumbnail = "Content Thumbnail URL is required";
    if (!description) newErrors.description = "Description is required";

    if (postType === "footballco") {
      if (!contentVideo) newErrors.contentVideo = "Video is required";
    }

    if (postType === "footballco") {
      if (!description2) newErrors.description2 = "Description is required";
    }

    if (postType === "footballco") {
      if (!contentImageGallery)
        newErrors.contentImageGallery = "Image Gallerty is required";
    }

    setErrors(newErrors);

    // If there are validation errors, prevent form submission
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const postData = new FormData();

    // Append other fields to FormData
    postData.append("postType", postType);
    postData.append("title", title);
    postData.append("shortDescription", shortDescription);
    postData.append("subTitle", subTitle);
    postData.append("description", description);
    postData.append("thumbnail", contentThumbnail);

    // slika za edit opciju mora biti izmenjena da bi se poslala
    if (contentImage) {
      if (isEditRoute) {
        if (contentImage !== editBlogContext.contentImage) {
          postData.append("image", contentImage);
        }
      } else {
        postData.append("image", contentImage);
      }
    }

    // Video za edit opciju mora biti izmenjena da bi se poslala
    if (contentVideo && postType === "footballco") {
      if (isEditRoute) {
        if (contentVideo !== editBlogContext.contentVideo) {
          postData.append("video", contentVideo);
        }
      } else {
        postData.append("video", contentVideo);
      }
    }

    if (description2 && postType === "footballco") {
      postData.append("description2", description2);
    }

    // Galerija slika za edit opciju mora biti izmenjena da bi se poslala
    if (contentImageGallery && postType === "footballco") {
      if (isEditRoute) {
        if (contentImageGallery !== editBlogContext.contentGallery) {
          Array.from(contentImageGallery).forEach((file, index) => {
            postData.append("gallery", file, file.name);
          });
        }
      } else {
        Array.from(contentImageGallery).forEach((file, index) => {
          postData.append("gallery", file, file.name);
        });
      }
    }

    setBlogLoading(true);
    if (isEditRoute) {
      updateBlog(postData);
    } else {
      createBlog(postData);
    }
  };

  const updateBlog = async (postData) => {
    try {
      const { success } = await axios.put(
        process.env.REACT_APP_API_URL + "posts/" + editBlogContext._id,
        postData
      );
      toast.success("Blog uspesno izmenjen!");
      setBlogLoading(false);
      navigate("/blogs/view");
    } catch (error) {
      toast.error("Greska pri izmeni bloga!");
      setBlogLoading(false);
    }
  };

  const createBlog = async (postData) => {
    try {
      const { success } = await axios.post(
        process.env.REACT_APP_API_URL + "posts",
        postData
      );
      toast.success("Blog uspesno kreiran!");
      setBlogLoading(false);
      navigate("/blogs/view");
    } catch (error) {
      toast.error("Greska pri kreiranju bloga!");
      setBlogLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Create Blog</h2>
      <form noValidate onSubmit={(e) => handleSubmit(e)}>
        <div className="mb-3">
          <label htmlFor="postType" className="form-label">
            Tip bloga:
          </label>
          <select
            className={`form-control ${errors.postType ? "is-invalid" : ""}`}
            id="postType"
            value={postType}
            onChange={(e) => setPostType(e.target.value)}
            required
            disabled={isEditRoute}
          >
            <option value="">Selektuj tip bloga</option>
            <option value="influencers">Influencers</option>
            <option value="footballco">Footballco</option>
          </select>

          {errors.postType && (
            <div className="invalid-feedback">{errors.postType}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="shortDescription" className="form-label">
            Kratak opis:
          </label>
          <input
            type="text"
            className={`form-control ${
              errors.shortDescription ? "is-invalid" : ""
            }`}
            id="shortDescription"
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
            required
          />
          {errors.shortDescription && (
            <div className="invalid-feedback">{errors.shortDescription}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Naslov:
          </label>
          <input
            type="text"
            className={`form-control ${errors.title ? "is-invalid" : ""}`}
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          {errors.title && (
            <div className="invalid-feedback">{errors.title}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="subTitle" className="form-label">
            Podnaslov:
          </label>
          <input
            type="text"
            className={`form-control ${errors.subTitle ? "is-invalid" : ""}`}
            id="subTitle"
            value={subTitle}
            onChange={(e) => setSubTitle(e.target.value)}
            required
          />
          {errors.subTitle && (
            <div className="invalid-feedback">{errors.subTitle}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="contentImage" className="form-label">
            Slika:
          </label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            className={`form-control ${
              errors.contentImage ? "is-invalid" : ""
            }`}
            id="contentImage"
            onChange={(e) => {
              setContentImage(e.target.files[0]);
              setImgSrc(URL.createObjectURL(e.target.files[0]));
            }}
            required
          />
          {errors.contentImage && (
            <div className="invalid-feedback">{errors.contentImage}</div>
          )}
          <br />
          {imgSrc !== "" && (
            <div>
              <img
                crossOrigin="anonymous"
                src={imgSrc}
                alt="Preview"
                style={{ width: "200px", height: "200px" }}
              />
            </div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="contentThumbnail" className="form-label">
            Thumbnail:
          </label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            className={`form-control ${
              errors.contentThumbnail ? "is-invalid" : ""
            }`}
            id="contentImage"
            onChange={(e) => {
              setContentThumbnail(e.target.files[0]);
              setThumbnailSrc(URL.createObjectURL(e.target.files[0]));
            }}
            required
          />
          {errors.contentThumbnail && (
            <div className="invalid-feedback">{errors.contentThumbnail}</div>
          )}
          <br />
          {thumnailSrc !== "" && (
            <div>
              <img
                crossOrigin="anonymous"
                src={thumnailSrc}
                alt="Preview"
                style={{ width: "200px", height: "200px" }}
              />
            </div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Opis:
          </label>
          <textarea
            className={`form-control ${errors.description ? "is-invalid" : ""}`}
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          {errors.description && (
            <div className="invalid-feedback">{errors.description}</div>
          )}
        </div>

        {(postType === "footballco") && (
          <div className="mb-3">
            <label htmlFor="contentVideo" className="form-label">
              Video:
            </label>
            <input
              type="file"
              id="contentVideo"
              className={`form-control ${
                errors.contentVideo ? "is-invalid" : ""
              }`}
              accept=".mp4"
              onChange={(e) => {
                if(e.target.files[0].size > 100000000) {
                  toast.error("Preveliki fajl!");
                  e.target.value = "";
                  return;
                }
                setContentVideo(e.target.files[0]);
                setVideoSrc(URL.createObjectURL(e.target.files[0]));
              }}
              required
            />
            {errors.contentVideo && (
              <div className="invalid-feedback">{errors.contentVideo}</div>
            )}
            {videoSrc !== "" && (
              <div>
                <video
                  controls
                  muted
                  crossOrigin="anonymous"
                  src={videoSrc}
                  style={{ width: "300px", height: "200px" }}
                />
              </div>
            )}
          </div>
        )}

        {(postType === "footballco") && (
          <div className="mb-3">
            <label htmlFor="description2" className="form-label">
              Opis 2:
            </label>
            <textarea
              className={`form-control ${
                errors.description2 ? "is-invalid" : ""
              }`}
              id="description2"
              value={description2}
              onChange={(e) => setDescription2(e.target.value)}
              required
            />
            {errors.description2 && (
              <div className="invalid-feedback">{errors.description2}</div>
            )}
          </div>
        )}

        {postType == "footballco" && (
          <div className="mb-3">
            <label htmlFor="contentImageGallery" className="form-label">
              Galerija:
            </label>
            <input
              type="file"
              multiple="true"
              accept=".jpg, .jpeg, .png"
              className={`form-control ${
                errors.contentImageGallery ? "is-invalid" : ""
              }`}
              id="contentImageGallery"
              onChange={(e) => {
                setContentImageGallery(e.target.files);
                setGalerySrc(
                  Array.from(e.target.files).map((file) =>
                    URL.createObjectURL(file)
                  )
                );
              }}
              required
            />
            {galerySrc && (
              <div className="row mt-2">
                {galerySrc.map((img) => {
                  return (
                    <img
                      crossOrigin="anonymous"
                      src={img}
                      alt="Preview"
                      style={{ width: "200px", height: "200px" }}
                    />
                  );
                })}
              </div>
            )}
            {errors.contentImageGallery && (
              <div className="invalid-feedback">
                {errors.contentImageGallery}
              </div>
            )}
          </div>
        )}

        <button
          type="submit"
          disabled={loading}
          className="btn btn-primary mb-2"
        >
          {isEditRoute ? "Izmeni" : "Kreiraj"}
        </button>
      </form>
      <SpinnerOverlay isLoading={loading} />
    </div>
  );
};

export default CreatePost;
