import React, { useEffect, useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import './table.css';

const ViewTable = ({children, data = [], itemsPerPage, setCurrentItems }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    setCurrentItems(data?.slice(indexOfFirstItem, indexOfLastItem));
  },[data, currentPage])

  return (
    <div>
      <Table striped bordered hover>
       {children}
      </Table>

      {data && data.length > 0 && <Pagination>
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages).keys()]?.map((number) => (
          <Pagination.Item
            key={number + 1}
            active={number + 1 === currentPage}
            onClick={() => paginate(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>}
    </div>
  );
};

export default ViewTable;