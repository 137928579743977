import formatDisplayDate from "../../../helpers/formatDisplayDate";

const MatchTableBody = ({ data, edit, deleteMatch, addResult }) => {
  return (
    <tbody>
      {data?.map((match, index) => (
        <tr key={index}>
          <td className="text-overflow">{match?.gameWeek ? [match?.gameWeek.weekNumber, match?.gameWeek.gameType].join(' - ') : "Nije dodeljena"}</td>
          <td className="text-overflow">{match?.homeTeam.name}</td>
          <td className="text-overflow">{match?.awayTeam.name}</td>
          <td className="text-overflow">{formatDisplayDate(match?.matchDate)}</td>
          <td className="text-overflow">{formatDisplayDate(match?.createdAt)}</td>
          <td className="text-overflow">{formatDisplayDate(match?.updatedAt)}</td>
          <td className="text-overflow">
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => edit(match)}
            >
              Pregled
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => addResult(match)}
            >
              Unesi Rezultate
            </button>
            <button
              type="button"
              class="btn btn-danger"
              onClick={() => deleteMatch(match)}
            >
              Obrisi
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default MatchTableBody;
