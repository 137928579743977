import React from "react";
import QuestionForm from "../components/quiz/QuestionForm";

function CreateQuestion() {
  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-lg-9">
            <QuestionForm />
        </div>
      </div>
    </div>
  );
}

export default CreateQuestion;
