import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import apiService from "../../services/apiService";
import { createDateTimeRangeForHalfOfMonth } from "../../helpers/dateTimeRange";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";
import SpinnerOverlay from "../../helpers/SpinnerOverlay";

const quizTypes = {
  alwaysOn: "always-on",
  popUp: "pop-up",
};

const quizConfig = {
  "always-on": {
    name: quizTypes.alwaysOn,
    types: ["entire", "second-half"],
    questions: 15,
  },
  "pop-up": {
    name: quizTypes.popUp,
    questions: 10,
  },
};

const QuizForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quizType, setQuizType] = useState(quizTypes.alwaysOn);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [chosenQuestions, setChosenQuestions] = useState([]);
  const [subQuizType, setSubQuizType] = useState(
    quizConfig["always-on"].types[0]
  );
  const [quizMonth, setQuizMonth] = useState("");

  const handleQuizTypeChange = (e) => {
    const selectedQuizType = e.target.value;
    setQuizType(selectedQuizType);

    // Reset chosen questions when quiz type changes
    setChosenQuestions([]);
  };

  const handleAddQuestion = (question) => {
    const availableQuestionNumber = quizConfig[quizType].questions;
    if (chosenQuestions.length < availableQuestionNumber) {
      setChosenQuestions([...chosenQuestions, question]);
      setAvailableQuestions((currentAvailableQuestions) => {
        return currentAvailableQuestions.filter((q) => q._id !== question._id);
      });
    } else {
      toast.error(`Maksimalan broj pitanja je ${availableQuestionNumber}!`);
    }
  };

  const handleRemoveQuestion = (question) => {
    setAvailableQuestions([...availableQuestions, question]);
    setChosenQuestions(chosenQuestions.filter((q) => q._id !== question._id));
  };

  const handleStartDateChange = (e) => {
    setStartDate(e);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e);
  };

  const handleSubQuizTypeChange = (e) => {
    const selectedSubQuizType = e.target.value;
    setSubQuizType(selectedSubQuizType);
  };

  const handleMonthChange = (e) => {
    setQuizMonth(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (id) {
      deleteQuiz();
      return;
    }

    const quizData = {
      quizType: quizType,
      title: title,
      description: description,
      startDate: getDateTime(startDate, quizType),
      endDate: getDateTime(endDate, quizType),
      subQuizType: quizType == quizTypes.alwaysOn ? subQuizType : null,
      questions: chosenQuestions.map((e) => e._id),
    };

    const availableQuestionNumber = quizConfig[quizType].questions;
    if (chosenQuestions.length < availableQuestionNumber) {
      toast.error(`Izaberi pitanja`);
      return;
    }

    setLoading(true);
    createQuiz(quizData);
  };

  const getDateTime = (date, quizType) => {
    return quizType == quizTypes.alwaysOn
      ? date.toISOString()
      : moment(date).add(59, "second").toISOString();
  };

  const getQuestions = async (choosenQuestions = []) => {
    const [success, error] = await apiService.get("questions");

    if (error) {
      console.log(error);
      return;
    }

    if (id) {
      const questions = success.data.filter(
        (e) => !choosenQuestions.find((q) => q._id == e._id)
      );
      setAvailableQuestions(questions);
    } else {
      if (success.data.length > 0) {
        setAvailableQuestions(success.data);
      } else {
        toast.error("Nema pitanja u bazi!");
      }
    }
  };

  const createQuiz = async (quizData) => {
    const [success, error] = await apiService.post("quiz", quizData);

    setLoading(false);
    if (error) {
      toast.error("Kreiranje kviza nije uspesno!");
      return;
    }

    toast.success("Kviz je uspešno kreiran!");
    navigate("/quiz/view");
  };

  const deleteQuiz = async () => {
    const [success, error] = await apiService.delete(`quiz/${id}`);

    setLoading(false);
    if (error) {
      toast.error("Brisanje kviza nije uspesno!");
      return;
    }

    toast.success("Kviz je uspešno obrisan!");
    navigate("/quiz/view");
  };

  const getQuiz = async () => {
    const [success, error] = await apiService.get(`quiz/${id}`);

    if (error) {
      toast.error("Greska pri dohvatanju kviza!");
      return;
    }
    if (success.data) {
      const { quizType, startDate, endDate, description, title, questions, subQuizType } =
        success.data;
      if (quizType == quizTypes.popUp) {
        setStartDate(moment(startDate));
        setEndDate(moment(endDate));
      }
      setDescription(description);
      setTitle(title);
      setChosenQuestions(questions);
      setQuizMonth(moment(startDate).format("YYYY-MM"));
      setQuizType(quizType);
      setSubQuizType(subQuizType);
      getQuestions(questions);
    }
  };

  useEffect(() => {
    if (id) {
      getQuiz();
    } else {
      getQuestions();
    }
  }, []);

  useEffect(() => {
    if (!id) {
      setStartDate("");
      setEndDate("");
      setDescription("");
      setTitle("");
      setAvailableQuestions([]);
      setChosenQuestions([]);
      setQuizMonth("");
    }
  }, [id]);

  useEffect(() => {
    if (quizType == quizTypes.alwaysOn && quizMonth !== "") {
      const result = createDateTimeRangeForHalfOfMonth(
        quizMonth,
        subQuizType
      );
      setStartDate(result.startDate);
      setEndDate(result.endDate);
    }
  }, [subQuizType, quizMonth]);

  useEffect(() => {
    if (!id) {
      setAvailableQuestions([]);
      setChosenQuestions([]);
      getQuestions();
    }
  }, [quizType]);

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Naslov kviza:
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Opis:
          </label>
          <textarea
            className="form-control"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="4"
            required
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="quizType" className="form-label">
            Tip:
          </label>
          <select
            className="form-select"
            id="quizType"
            disabled={id != null}
            value={quizType}
            onChange={handleQuizTypeChange}
          >
            <option value={quizTypes.alwaysOn}>Always-on</option>
            <option value={quizTypes.popUp}>Pop-up</option>
          </select>
        </div>

        {quizType === quizTypes.alwaysOn && (
          <div className="row">
            <div className="col-md-4 mb-3">
              <label htmlFor="startDate" className="form-label">
                Mesec:
              </label>
              <input
                type="month"
                className="form-control"
                id="startDate"
                value={quizMonth}
                onChange={handleMonthChange}
                required
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="quizType" className="form-label">
                Deo meseca:
              </label>
              <select
                className="form-select"
                id="quizType"
                value={subQuizType}
                onChange={handleSubQuizTypeChange}
              >
                <option value={quizConfig[quizTypes.alwaysOn].types[0]}>
                  Ceo mesec
                </option>
                <option value={quizConfig[quizTypes.alwaysOn].types[1]}>
                  Prvi deo meseca
                </option>
              </select>
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="quizType" className="form-label">
                Datum pocetka i zavrsetka:
              </label>
              <input
                disabled
                className="form-control"
                id="quizType"
                value={moment(startDate).format("YYYY-MM-DD HH:mm:ss").toString() + " - " + moment(endDate).format("YYYY-MM-DD HH:mm:ss").toString()}
                onChange={handleQuizTypeChange}
              ></input>
            </div>
          </div>
        )}

        {quizType === quizTypes.popUp && (
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="startDate" className="form-label">
                Datum početka kviza:
              </label>
              <DateTimePicker
                value={moment(startDate)}
                className="form-control"
                required
                onChange={handleStartDateChange}
                timeFormat="HH:mm"
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="endDate" className="form-label">
                Datum završetka kviza:
              </label>
              <DateTimePicker
                className="form-control"
                required
                value={moment(endDate)}
                onChange={handleEndDateChange}
                timeFormat="HH:mm"
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col">
            <h5>Dostupna pitanja:</h5>
            <div
              className="available-questions-container"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <ul className="list-group ml-3">
                <div className="row">
                  {availableQuestions.map((question, index) => (
                    <div key={index} className="col-md-6 mb-1">
                      <li
                        key={question.id}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {question.questionText}
                        </div>
                        <button
                          type="button"
                          className="btn btn-sm btn-success"
                          onClick={() => handleAddQuestion(question)}
                        >
                          Dodaj
                        </button>
                      </li>
                    </div>
                  ))}
                </div>
              </ul>
            </div>
          </div>

          <div className="col">
            <h5>Izabrana pitanja:</h5>
            <div
              className="available-questions-container"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <ul className="list-group">
                {chosenQuestions.map((question, index) => (
                  <li
                    key={question.id}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    {index + 1}: {question.questionText}
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => handleRemoveQuestion(question)}
                    >
                      Ukloni
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {!id && (
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary mt-3 mb-2"
          >
            Kreiraj kviz
          </button>
        )}

        {id && (
          <button
            type="submit"
            disabled={loading}
            className="btn btn-danger mt-3 mb-2"
          >
            Obrisi kviz
          </button>
        )}
      </form>
      <SpinnerOverlay isLoading={loading} />
    </div>
  );
};

export default QuizForm;
