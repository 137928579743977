import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ViewQuizTable from '../components/quiz/components/ViewQuizTable';
import apiService from '../services/apiService';
import { EDIT_QUIZ_ROUTE } from '../routes/routeConstants';
import { editQuizSignal } from '../signals';

function ViewQuizzes() {
  const [ allQuizzes, setAllQuizzes ] = useState([]);
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const headers = [
    'Naslov',
    'Opis',
    'Tip',
    'Pod tip',
    'Pocetak',
    'Kraj',
    'Krairan',
    'Izmenjen',
    'Izmeni/Obrisi'
  ];
    
  const edit = (quiz) => {
    editQuizSignal.value = quiz;
    navigate(EDIT_QUIZ_ROUTE + '/' + quiz._id);
  };
  
  const getQuizzes = async() => {
      const [success, error] = await apiService.get('quiz');

      if(error) {
        toast.error("Greska pri ucitavanju kvizova!");
        return; 
      }

      setAllQuizzes(success.data)
  }

  useEffect(() => {
    getQuizzes()
  }, []);

  return (
    <div  className="px-5 py-5">
      <h1>Svi kvizovi</h1>
      <ViewQuizTable data={allQuizzes} headers={headers} itemsPerPage={itemsPerPage} edit={edit}/>
    </div>
  );
}

export default ViewQuizzes;