import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiService from "../services/apiService";

const months = ["March", "April", "May", "June", "July", "August", "September"];

const ViewRankList = () => {
  const [rangList, setRangList] = useState({
    month: "march",
    finalLeaderboard: [
      {
        userId: "65ecbee67cc851af1490bfa8",
        firstName: "Rodoljub",
        lastName: "Žerajić",
        place: 1,
        phone: "+381602140481",
        points: 90,
      },
      {
        userId: "65ecbee67cc851af1490bf76",
        firstName: "Milisav",
        lastName: "Kvrgić",
        place: 2,
        phone: "+381606906783",
        points: 50,
      },
    ],
  });
  const [selectedMonth, setSelectedMonth] = useState(months[0]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const getRankList = async (month) => {
    const [success, error] = await apiService.get(
      `ranks?month=${month.toLowerCase()}`
    );

    if (error) {
      toast.error("Greska pri ucitavanju rang liste!");
      return;
    }

    setRangList(success.data);
  };

  useEffect(() => {
    getRankList(selectedMonth);
  }, [selectedMonth]);

  return (
    <div className="px-5 py-5">
      <div className="dropdown mb-3">
        <select
          className="form-select"
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          {months.map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div className="table-responsive">
        <h2>{selectedMonth.toLocaleUpperCase()}</h2>
        <table className="table table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">Mesto</th>
              <th className="text-center">Ime i Prezime</th>
              <th className="text-center">Telefon</th>
              <th className="text-center">Poeni</th>
            </tr>
          </thead>
          <tbody>
            {rangList.finalLeaderboard.map((item, index) => (
              <tr key={index}>
                <td className="text-center">{item.userId}</td>
                <td className="text-center">{item.place}</td>
                <td className="text-center">
                  {item.firstName + " " + item.lastName}
                </td>
                <td className="text-center">{item.phone}</td>
                <td className="text-center">{item.points}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewRankList;
