import React from 'react';
import './SpinnerOverlay.css';

const SpinnerOverlay = ({ isLoading }) => {
  return (
    isLoading && (
      <div className="spinner-overlay">
        <div className="spinner"></div>
      </div>
    )
  );
};

export default SpinnerOverlay;