import { Navigate, Outlet } from "react-router-dom";
import { VIEW_BLOGS_ROUTE } from "./routeConstants";

const isAuthenticated = () => {
  var token = localStorage.getItem('token');
  if (token == null) {
    return false;
  } else {
    return true;
  }
};

export const PublicRoute = () => {

  if (isAuthenticated()) {
    return <Navigate to={VIEW_BLOGS_ROUTE} />;
  }

  return (
      <Outlet className="example" />
  )
};