import React from 'react';
import QuizForm from '../components/quiz/QuizForm';

function CreateQuiz() {
  return (
    <div>
      <QuizForm />
    </div>
  );
}

export default CreateQuiz;